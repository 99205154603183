
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import { plainToInstance } from 'class-transformer'
  import { Auto } from '@/entities/public'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { stringifySafe, updateNestedObject } from '@/utils/general'
  import { Debounce } from '@/utils/decorators'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import dayjs from 'dayjs'
  import _ from 'lodash'
  import { Generation } from '@/entities/vehicle'
  import { mapActions } from 'vuex'
  import InspectionSummary from '@/components/forms/inspection/InspectionSummary.vue'

@Component({
  components: { InspectionSummary, GAlert, BaseForm },
  methods: {
    ...mapActions('resources/form', ['findPpu']),
  },
  computed: {},
})
  export default class AutoForm extends GForm {
  autoInfo = {
    brand: null,
    models: null,
    versions: null,
    year: null,
  }

  autos = []
  autoDetail = null
  showInspectionDetail = false
  inspection = null
  disabledSend = false
  appraisal = null
  autoIsNotMatch = false
  loading = false
  previousAuto = {
    open: false,
    title: 'Este vehículo esta registrado en nuestra ',
    subtitle: '',
  }

  ppuMandatory = false
  validPpuLetter = null
  maxYear = null
  auto: Auto = plainToInstance(Auto, {})
  temporalAuto: Auto = plainToInstance(Auto, {})
  disabledFields = false
  displayGeneration = false
  isDeprecatedSelected = false
  auxGeneration: Generation | null = null
  message = ''
  messageInfo = ''
  messageNotMatch = ''
  autoAlert = {
    open: false,
    subtitle: '',
    title: '',
  }

  showDetail = false
  title = ''
  formData = {
    ppu: null,
    brand: null,
    model: null,
    version: null,
    year: null,
    generation: null,
  }

  fields = {
    brand: {
      properties: {},
      items: [],
      rules: [],
    },
    model: {
      properties: {},
      items: [],
      rules: [],
    },
    ppu: {
      properties: {},
      items: [],
      rules: [],
    },
    version: {
      properties: {},
      items: [],
      rules: [],
    },
    generation: {
      properties: {},
      items: [],
      rules: [],
    },
    year: {
      properties: {},
      items: [],
      rules: [],
    },
  }

  findPpu!: (ppu: string) => Promise<any>

  metadata = {}
  metadataCollection = {}

  async mounted () {
    await this.setMetadata()
    const { uid, id, model, title, metadataCollection } = this
    const autoId = id || uid

    if (model?.length && !isNaN(uid)) {
      await this.getModelInfo(model, uid)
    }

    if ((!isNaN(uid) && (model === '' || model === 'Auto')) || (!isNaN(id) && id)) {
      await this.getAutoInfo(autoId)
    }

    this.setTheBackup()

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(autoId))
    }

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'auto' } },
    })

    this.ppuMandatory = process?.[0]?.config?.ppuMandatory
    this.validPpuLetter = process?.[0]?.config?.validPpuLetter
    this.maxYear = process?.[0]?.config?.maxYear
  }

  async getModelInfo (model, id) {
    const appraisal = await this.fetchData({
      query: { name: 'fetch', model, params: { id } },
    })

    this.appraisal = appraisal

    this.disabledFields = !appraisal?.status?.isPending
  }

  async getAutoInfo (id) {
    const auto = await this.fetchData({
      query: { name: 'fetch', model: 'Auto', params: { id } },
    })
    let isNotQualify = false

    if (this.appraisal) {
      const { deal: { closingReason } } = this.appraisal

      isNotQualify = closingReason.name === 'not_qualify'
    }

    if (auto && !isNotQualify) {
      this.formData.year = auto.version.year.id
      this.formData.brand = auto.version.version.model.brand
      this.formData.model = auto.version.version.model
      this.formData.version = auto.version
      this.formData.ppu = auto?.registrationPlate
      this.formData.generation = auto.generation
      this.auto = auto
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Auto', 'auto')
    const { fields, form } = metadata as Form

    this.title = form.title
    this.metadataCollection = metadata
    this.fields.brand.properties = fields.brand.properties
    this.fields.model.properties = fields.model.properties
    this.fields.version.properties = fields.version.properties
    this.fields.year.properties = fields.year.properties
    this.fields.ppu.properties = fields.ppu.properties
    this.fields.generation.properties = fields.generation.properties

    this.fields.year.items = await this.fetchData({
      query: { name: 'find', model: 'Year' },
    })
  }

  setTheBackup () {
    const { backup } = this
    if (backup && 'auto' in backup && backup.auto) {
      this.formData.year = backup.auto.version.year
      this.formData.brand = backup.auto.version.version.model.brand
      this.formData.model = backup.auto.version.version.model
      this.formData.version = backup.auto.version
      this.formData.ppu = backup.auto.registrationPlate
    }
  }

  get disabledPlate () {
    const { auto } = this

    return Boolean(auto?.registrationPlate) && auto.registrationPlate.length === 6 && Boolean(auto.id)
  }

  displayDetail () {
    const { auto, metadataCollection } = this

    this.metadata = {
      data: auto,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(auto?.version?.id)
  }

  saveBackup () {
    const { backup, auto, formData: { ppu } } = this

    if (backup) {
      auto.registrationPlate = ppu
      backup.auto = auto
      this.setBackup(backup)
    } else {
      this.setBackup({ auto })
    }
  }

  async send () {
    const { ppuErrorMessage } = this
    if (!this.$refs.form.validate() || ppuErrorMessage.length) {
      return
    }
    this.loadingForm = true
    this.saveBackup()
    await this.close()
  }

  @Watch('formData.year', { immediate: true })
  async onYearChange (year) {
    if (year) {
      if (!this.autoInfo && !this.backup?.auto) {
        this.formData.brand = null
        this.formData.model = null
        this.formData.version = null
      } else if (!this.backup?.auto) {
        this.fields.brand.items = [this.autoInfo?.brand]
        return
      }
      const { metadataCollection, formData } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const query = updateNestedObject(fields.brand.computed.queries.items.where, '_eq', year.id)
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'Brand',
            order: {
              name: 'asc',
            },
          },
          filter: { ...query },
        })
        this.fields.brand.items = items

        if (formData.brand && !items.some(item => item.id === formData.brand?.id)) {
          formData.brand = null
        }
      }
    }
  }

  @Watch('formData.brand', { immediate: true })
  async onBrandChange (brand) {
    this.cleanAuto()
    if (!this.backup?.auto) {
      this.formData.model = null
      this.formData.version = null
      this.formData.generation = null
    }

    if (brand) {
      if (!this.autoInfo?.brand && !this.backup?.auto) {
        this.formData.model = null
        this.formData.version = null
      } else if (!this.backup?.auto) {
        this.fields.model.items = this.autoInfo?.models
        if (this.autoInfo?.models?.length === 1) {
          this.formData.model = this.autoInfo.models[0]
        }
        return
      }

      const { metadataCollection } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const query = updateNestedObject(fields.model.computed.queries.items.where, '_eq',
          [
            { target: 3, value: brand.id },
            { target: 2, value: this.formData.year.id },
          ])
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'Model',
            order: {
              name: 'asc',
            },
          },
          filter: { ...query },
        })
        this.fields.model.items = items
        if (this.formData.model && !items.some(item => item.id === this.formData.model?.id)) {
          this.formData.model = null
        }
      }
    }
  }

  @Watch('formData.model', { immediate: true })
  async onModelChange (model) {
    this.cleanAuto()
    if (!this.backup?.auto) {
      this.formData.version = null
      this.formData.generation = null
    }
    if (model) {
      if (!this.autoInfo && !this.backup?.auto) {
        this.formData.version = null
      }
      const { metadataCollection, autoInfo } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const query = updateNestedObject(fields.version.computed.queries.items.where, '_eq',
          [
            { target: 2, value: model.id },
            { target: 1, value: this.formData.year.id },
          ])
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'VersionYear',
            order: {
              version: { name: 'asc' },
            },
          },
          filter: { ...query },
        })
        this.fields.version.items = items
        if (!this.backup?.auto) {
          this.formData.version = items.find(item => item.version.id === autoInfo?.versions?.[0].id)
        }
      }
    }
  }

  @Watch('formData.version', { immediate: true, deep: true })
  async onVersionChange (version, oldVersion) {
    if (!version) {
      this.cleanAuto()
      if (!this.backup?.auto) {
        this.auto.version = null
      }
      return
    }

    if (version?.id && oldVersion?.id !== version?.id) {
      this.cleanAuto()
      if (!this.backup?.auto) {
        this.formData.generation = null
      }
      const generation = await this.fetchData({
        query: { name: 'find', model: 'Generation' },
        filter: { id_version_year: { _eq: version.id } },
      })

      if (generation?.length > 1) {
        this.displayGeneration = true
        this.fields.generation.items = generation
      } else if (generation?.length === 1) {
        this.fields.generation.items = []
        this.displayGeneration = false
        this.auto.generation = generation[0]
      }
    }
    this.auto.registrationPlate = this.formData.ppu
    this.auto.version = _.cloneDeep(version)
    this.displayDetailWithDelay()
  }

  @Watch('formData.generation', { immediate: true, deep: true })
  async onGenerationChange (generation, oldGeneration) {
    if (generation?.id && oldGeneration?.id && oldGeneration?.id !== generation?.id) {
      this.auto.generation = null
      this.displayDetail()
    }
    if (generation) {
      this.auto.generation = generation
    }
    this.displayDetailWithDelay()
  }

  @Debounce(500)
  displayDetailWithDelay () {
    this.displayDetail()
  }

  @Watch('formData.ppu', { immediate: true })
  async onPpuChange (ppu) {
    if (ppu?.length === 6) {
      this.isDeprecatedSelected = false
      await this.searchAuto(ppu)
    }
  }

  @Debounce(500)
  async searchAuto (input) {
    this.loading = true
    this.cleanAuto()
    this.message = ''
    if (!this.backup?.auto) {
      this.clearVehicleInfo()
    }
    if (!input?.length || input?.length < 6) {
      this.loading = false
      return null
    }

    const { metadataCollection, appraisal } = this

    const { fields } = metadataCollection as Form
    const query = updateNestedObject(fields.auto.computed.queries.items.where, '_eq', input)
    this.autos = await this.fetchData({
      query: { name: 'find', model: 'Auto', order: { id: 'desc' } },
      filter: { ...query },
      force: true,
    })
    const auto = this.autos?.[0]

    if (this.autos.length) {
      this.autoIsNotMatch = auto?.status?.isNotMatch
      if (!auto?.status?.isNotMatch && !appraisal) {
        this.setAutoValues(auto, input)
      } else if (!appraisal) {
        await this.findPpuInDb(input)
        this.auto.registrationPlate = input
      } else {
        await this.checkAutoIsNotQualify(appraisal, input, auto)
      }
    } else {
      await this.findPpuInDb(input)
      this.auto.registrationPlate = input
      this.disabledSend = false
    }
    this.loading = false
  }

  setAutoValues (auto, ppu) {
    if (!auto.status.isSold && !auto.status.isDeprecated) {
      this.message = `El vehículo se encuentra en estado ${auto.status.description}, no puedes crear una tasación con este vehículo`
    } else {
      this.temporalAuto = auto
      this.autoAlert = {
        open: true,
        title: `El vehículo con la patente: ${ppu} ya se encuentra registrado en nuestro sistema ¿desea continuar?`,
        subtitle: `${auto.alertName}`,
      }
      this.auto.registrationPlate = auto?.registrationPlate
    }
  }

  async checkAutoIsNotQualify (appraisal, ppu, auto) {
    const notQualify = appraisal?.deal?.closingReason?.name === 'not_qualify'
    const isSamePPu = appraisal?.deal?.auto?.registrationPlate === ppu
    const notMatch = auto?.status?.isNotMatch
    if (notQualify && isSamePPu) {
      this.message = `El vehículo "No califica" para este negocio`
      this.disabledSend = true
      return
    } else if (!notMatch) {
      this.setAutoValues(auto, ppu)
    } else {
      await this.findPpuInDb(ppu)
      this.auto.registrationPlate = ppu
    }
    this.disabledSend = false
  }

  async findPpuInDb (ppu) {
    this.messageInfo = ''
    const info = await this.findPpu(ppu)

    if (info?.auto?.year === -1) return

    if (!info?.auto?.year) {
      const { validPpuLetter } = this

      if (ppu?.length && validPpuLetter && !this.isValidLicensePlate(ppu)) {
        this.messageInfo = `Patente no figura en nuestro registro, ingresa la información manualmente`
      } else {
        this.setMessage(false, '')
      }
      return
    }

    this.autoInfo = info.auto

    const { auto: { brand, models, year, versions } } = info
    const { fields, maxYear } = this

    const currentYear = dayjs().year()

    if (maxYear && info.auto.year < currentYear - maxYear) {
      this.setMessage(true, `El vehículo ingresado tiene más de ${maxYear} años`)
      return
    } else {
      this.setMessage(false, '')
    }

    this.formData.year = fields.year.items.find(item => item.id === year)
    this.formData.brand = brand

    if (models?.length === 1) {
      this.formData.model = models[0]
    } else {
      this.fields.model.items = models
    }

    this.formData.version = versions[0]
  }

  get disableBrand () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { brand } = autoInfo

    return Boolean(brand)
  }

  get disabledSendVersion () {
    const { auto } = this
    return !auto.version?.id
  }

  get disableModel () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { models } = autoInfo

    return models?.length === 1
  }

  get disableVersion () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { versions } = autoInfo

    return versions?.length === 1
  }

  get disableYear () {
    const { autoInfo, loading } = this

    if (loading) {
      return true
    }

    if (!autoInfo) {
      return false
    }

    const { year } = autoInfo

    return Boolean(year)
  }

  get isPpuValid () {
    const { formData: { ppu } } = this

    return ppu?.length !== 6
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  async setAuto (auto) {
    this.auto = auto
    this.saveBackup()
    this.autoDetail = auto
    this.formData = {
      ppu: auto.registrationPlate,
      brand: auto.version.version.model.brand,
      model: auto.version.version.model,
      version: auto.version,
      year: auto.version.year,
      generation: auto.generation,
    }
    this.disabledFields = true
    this.autoAlert = {
      open: false,
      title: ``,
      subtitle: ``,
    }
    this.temporalAuto = null

    const appraisals = await this.fetchData({
      query: { name: 'find', model: 'Appraisal' },
      filter: { deal: { id_auto: { _eq: auto.id } } },
    })

    if (appraisals.length) {
      const appraisal = appraisals[appraisals.length - 1]
      const inspection = await this.fetchData({
        query: { name: 'find', model: 'Inspection', order: { id: 'desc' } },
        filter: {
          _and: [
            { id_appraisal: { _eq: appraisal.id } },
            { inspector_qualification: { _is_null: false } },
            { supervisor_qualification: { _is_null: false } },
          ],
        },
      })
      if (inspection?.length) {
        this.inspection = inspection[0]
        this.showInspectionDetail = true
      } else {
        this.closeForm()
      }
    } else {
      this.previousAuto = {
        open: true,
        title: `El vehículo con la patente: ${auto.ppu} ya se encuentra registrado en nuestro sistema ¿desea continuar?`,
        subtitle: `${auto.alertName}`,
      }
    }
  }

  cleanAuto () {
    if (!this?.backup?.auto) {
      this.auto = plainToInstance(Auto, {})
    }
    this.temporalAuto = null
    this.displayDetail()
  }

  cleanForm () {
    this.isDeprecatedSelected = true
    this.previousAuto = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.cleanAuto()
    this.formData = {
      ppu: null,
      brand: null,
      model: null,
      version: null,
      year: null,
      generation: null,
    }
    this.autoAlert = {
      open: false,
      title: ``,
      subtitle: ``,
    }
  }

  clearVehicleInfo () {
    this.autoInfo = null
    this.formData.brand = null
    this.formData.model = null
    this.formData.version = null
    this.formData.year = null
    this.formData.generation = null
  }

  isValidLicensePlate (ppu) {
    if (!ppu || typeof ppu !== 'string') return null
    if (ppu.length < 6) return false

    const firstLetter = ppu[0].toUpperCase()

    const { validPpuLetter } = this

    return firstLetter >= validPpuLetter.toUpperCase()
  }

  setMessage (flag, message) {
    this.disabledFields = flag
    this.message = message
  }

  @Watch('showInspectionDetail', { immediate: true })
  onShowInspectionDetailChange (showInspectionDetail, oldShowInspectionDetail) {
    if (!showInspectionDetail && oldShowInspectionDetail) {
      this.close()
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  async onFormDataChange (formData) {
    if (this.isDeprecatedSelected) return
    const autos = this.autos.filter(auto => auto.status.isNotMatch).map(auto => {
      return {
        versionYear: auto.version?.id,
        generation: auto.generation?.id,
      }
    })
    let notMatch = false
    autos?.forEach(auto => {
      if ((formData.version?.id && auto?.versionYear === formData.version?.id) || (formData.generation?.id && auto?.generation === formData.generation?.id)) {
        notMatch = true
      }
    })

    this.messageNotMatch = notMatch ? 'Ya fue reportado que los datos de este vehículo no coinciden con esta versión' : ''

    if (notMatch) {
      await this.getModels()
    }

    const auto = this.autos?.find(auto => auto.status.isDeprecated)

    if (this.auto?.id !== auto?.id) {
      this.isDeprecatedSelected = true
      await this.setAuto(auto)
    }
  }

  async getModels () {
    const { metadataCollection } = this
    const { fields } = metadataCollection as Form
    if (fields) {
      const query = updateNestedObject(fields.model.computed.queries.items.where, '_eq',
        [
          { target: 3, value: this.formData.brand.id },
          { target: 2, value: this.formData.year.id },
        ])
      this.fields.model.items = await this.fetchData({
        query: {
          name: 'find',
          model: 'Model',
          order: {
            name: 'asc',
          },
        },
        filter: { ...query },
      })
    }
  }

  closeForm () {
    this.close()
  }

  get ppuErrorMessage () {
    const { messageNotMatch, message } = this

    if (message?.length) {
      return [message]
    }

    if (messageNotMatch?.length) {
      return [messageNotMatch]
    }

    return ''
  }
  }
